body { background-color: #ffffff; }
body { color: var(--clr-65810); }
[data-pad="0"] { margin-top: 20px; }

[data-pad="1"] { margin-top: 5px; }

h1 {
font-family: 'Arsenal';
font-weight: 700;
font-style: normal;
line-height: 1.1;
text-transform: uppercase;
letter-spacing: 0;
font-size: 26px;

@media #{$medium-up} {
font-size: 32px;

}
@media #{$large-up} {
font-size: 36px;

}
}
h2 {
font-family: 'Arsenal';
font-weight: 700;
font-style: normal;
line-height: 1.1;
text-transform: uppercase;
letter-spacing: 0;
font-size: 22px;

@media #{$medium-up} {
font-size: 30px;

}
@media #{$large-up} {
font-size: 33px;

}
}
h3 {
font-family: 'Arsenal';
font-weight: 700;
font-style: normal;
line-height: 1.1;
text-transform: uppercase;
letter-spacing: 0;
font-size: 20px;

@media #{$medium-up} {
font-size: 26px;

}
@media #{$large-up} {
font-size: 30px;

}
}
h4 {
font-family: 'Arsenal';
font-weight: 700;
font-style: normal;
line-height: 1.1;
text-transform: uppercase;
letter-spacing: 0;
font-size: 18px;

@media #{$medium-up} {
font-size: 20px;

}
@media #{$large-up} {
font-size: 25px;

}
}
h5 {
font-family: 'Arsenal';
font-weight: 700;
font-style: normal;
line-height: 1.1;
text-transform: uppercase;
letter-spacing: 0;
font-size: 15px;

@media #{$large-up} {
font-size: 16px;

}
}
h6 {
font-family: 'Arsenal';
font-weight: 700;
font-style: normal;
line-height: 1.1;
letter-spacing: 0;
font-size: 16px;

}
.button {
font-family: 'Work Sans';
font-weight: normal;
font-style: normal;
line-height: 1.2;
letter-spacing: 0;
font-size: 14px;

}
.me-Quote .quote-body, .me-HtmlText blockquote {
font-family: 'Arapey';
font-weight: normal;
font-style: normal;
line-height: 1.5;
letter-spacing: 0;
font-size: 20px;

@media #{$medium-up} {
font-size: 22px;

}
}
.me-Quote .quote-author {
font-family: 'Work Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
letter-spacing: 0;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
ol,ul {
font-family: 'Work Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
letter-spacing: 0;
font-size: 16px;

@media #{$large-up} {
font-size: 18px;

}
}
ol,ul { margin-bottom: 1em; list-style-position: outside; }
ol { margin-left: 1.25em; list-style-type: decimal; }
ul { margin-left: 1.25em; list-style-type: disc; }
ul, ol { & & { margin-bottom: 0 } }
cite {
font-family: 'Noto Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
letter-spacing: 0;
font-size: 20px;

@media #{$medium-up} {
font-size: 22px;

}
@media #{$large-up} {
font-size: 24px;

}
}
summary {
font-family: 'Arsenal';
font-weight: 700;
font-style: normal;
line-height: 1.1;
letter-spacing: 0;
font-size: 22px;

}
nav.mainmenu > .menu-item > a {
font-family: 'Arsenal';
font-weight: 700;
font-style: normal;
line-height: 1.5;
text-transform: uppercase;
letter-spacing: 0;
font-size: 14px;

@media #{$medium-up} {
font-size: 18px;

}
}
nav.mainmenu > .menu-item > div > a {
font-family: 'Arsenal';
font-weight: 700;
font-style: normal;
line-height: 1.5;
text-transform: uppercase;
letter-spacing: 0;
font-size: 14px;

@media #{$medium-up} {
font-size: 18px;

}
}
nav.secondarymenu > .menu-item > a {
font-family: 'Arsenal';
font-weight: 700;
font-style: normal;
line-height: 1.5;
text-transform: uppercase;
letter-spacing: 0;
font-size: 14px;

}
nav.me-RespMenu.responsive-menu a {
font-family: 'Arsenal';
font-weight: 700;
font-style: normal;
line-height: 1.5;
text-transform: uppercase;
letter-spacing: 0;
font-size: 18px;

}
body {
font-family: 'Work Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
letter-spacing: 0;
font-size: 16px;

@media #{$large-up} {
font-size: 18px;

}
}
p { padding-bottom: 1em; }
a {color: var(--clr-65814);}
a:hover {color: var(--clr-65812);}
/* Primary:2 */
.MES2 {
background-color: var(--clr-65810);
color: #ffffff;
 }
/* Primary:3 */
.MES3 {
background-color: var(--clr-65810);
color: #ffffff;
 }
.MES3 {
background-color: var(--clr-65810);
color: #ffffff;
h1.MEC3, h2.MEC3, h3.MEC3, h4.MEC3, h5.MEC3, h6.MEC3 { color: #ffffff;
 }
 }
cite.MEC3{
color: #ffffff;
}
/* Secondary:4 */
.MES4 {
background-color: #95b4af;
color: var(--clr-65810);
 }
/* Secondary:5 */
.MES5 {
background-color: #95b4af;
color: #ffffff;
 }
.MES5 {
background-color: #95b4af;
color: #ffffff;
h1.MEC5, h2.MEC5, h3.MEC5, h4.MEC5, h5.MEC5, h6.MEC5 { color: #ffffff;
 }
 }
cite.MEC5{
color: #ffffff;
}
/* Alternate:6 */
.MES6 {
background-color: var(--clr-65815);
 }
/* Alternate:7 */
.MES7 {
background-color: #f2efec;
color: var(--clr-65810);
&:hover { color: var(--clr-65812);}
 }
/* Alternate:8 */
.MES8 {
background-color: var(--clr-65815);
 }
.MES8 {
background-color: var(--clr-65815);
 }
/* Alternate:9 */
.MES9 {
background-color: #f2efec;
color: var(--clr-65810);
 }
.MES9 {
background-color: #f2efec;
color: var(--clr-65810);
h1.MEC9, h2.MEC9, h3.MEC9, h4.MEC9, h5.MEC9, h6.MEC9 { color: var(--clr-65810);
 }
 }
cite.MEC9{
color: var(--clr-65810);
}
/* Light:10 */
.MES10 {
background-color: #d0dddb;
 }
/* Light:11 */
.MES11 {
background-color: #d0dddb;
 }
.MES11 {
background-color: #d0dddb;
 }
/* Shade 1:12 */
.MES12 {
background-color: #315157;
color: #ffffff;
 }
/* Shade 1:13 */
.MES13 {
background-color: #315157;
color: #ffffff;
 }
.MES13 {
background-color: #315157;
color: #ffffff;
h1.MEC13, h2.MEC13, h3.MEC13, h4.MEC13, h5.MEC13, h6.MEC13 { color: #ffffff;
 }
 }
cite.MEC13{
color: #ffffff;
}
/* Copyright panel:14 */
.MES14 {
color: var(--clr-65810);
font-size: 12.8px;
@media #{$large-up} {
font-size: 14.4px;
};
 }
.MES14 {
color: var(--clr-65810);
font-size: 12.8px;
@media #{$large-up} {
font-size: 14.4px;
};
h1.MEC14, h2.MEC14, h3.MEC14, h4.MEC14, h5.MEC14, h6.MEC14 { color: var(--clr-65810);
 }
h1.MEC14 { font-size: 20.8px; }
h2.MEC14 { font-size: 17.6px; }
h3.MEC14 { font-size: 16px; }
h4.MEC14 { font-size: 14.4px; }
h5.MEC14 { font-size: 12px; }
h6.MEC14 { font-size: 12.8px; }
 }
a.MEC14 { color: var(--clr-65810);
 }
cite.MEC14{
color: var(--clr-65810);
font-size: 12.8px;
@media #{$large-up} {
font-size: 14.4px;
};
}
/* News Grid:16 */
.MES16 {
background-color: transparent;
&:hover, &.hover { background-color: var(--clr-65815);}
padding: 15px;

@media #{$large-up} {
padding: 40px;

}
border-width: 1px;
border-style: solid;
border-color: var(--clr-65813);
border-top-style: solid;
border-right-style: solid;
border-bottom-style: solid;
border-left-style: solid;
 }
.MES16 {
background-color: transparent;
&:hover, &.hover { background-color: var(--clr-65815);}
padding: 15px;

@media #{$large-up} {
padding: 40px;

}
border-width: 1px;
border-style: solid;
border-color: var(--clr-65813);
border-top-style: solid;
border-right-style: solid;
border-bottom-style: solid;
border-left-style: solid;
 }
/* Main Menu:17 */
nav.me-Menu.MES17 {
& .menu-item.MEC17, & .menu-item.MEC17 > div.MEC17{ & > a.MEC17{color: var(--clr-65810);
text-transform: uppercase;
}
 &:hover > a.MEC17{color: var(--clr-65812);
}
 }
&.horizontal > .menu-item.MEC17 { border:0;

 border-right-width: 1px;}
&.vertical > .menu-item.MEC17 { border:0;

border-bottom-width: 1px; }
&.horizontal > .menu-item.MEC17 .menu-item { border:0;
border-bottom-width: 1px;
 }
&.horizontal > .menu-item.MEC17 .sub-menu { border:1px;
 }
& > .menu-item > a{padding: 10px 15px;}

& .sub-menu .menu-item a{padding: 10px 20px;}

@media #{$large-up} {
& .sub-menu .menu-item a{padding: 10px 30px;}

}


&.horizontal .menu-item.MEC17:hover {& > .pointer-wrap > .pointer{border-bottom: 10px solid #ffffff;}}
&.vertical .menu-item.MEC17:hover {& > .pointer-wrap > .pointer{border-right: 10px solid #ffffff;
}}

.menu-item:hover {
& > .sub-menu, & > .pointer-wrap > .sub-menu {
.menu-item.MEC17{background-color: #ffffff; &:hover {background-color: 4;}
}
& .menu-item.MEC17, & .menu-item.MEC17 > div.MEC17{ & > a.MEC17{color: var(--clr-65810);
}
 &:hover > a.MEC17{color: 2;
}
 }

}
}
 }
/* Paypal button:18 */
.MES18 {
background-color: var(--clr-65816);
color: #ffffff;
 }
/* Home slider:19 */
.MES19 {
& .slider-arrow {color: var(--clr-65817);
font-size: 100px;
@media #{$medium-up} {
font-size: 100px;
};
@media #{$large-up} {
font-size: 80px;
};
}& .slick-dots {text-align: center;
 button {margin: 0 1px;
;border-color: 16;
border-radius: 50%;
border-style:solid;
border-width:2px;
@media #{$medium-up} {
border-width: 2px;
};
@media #{$large-up} {
border-width: 2px;};
&:hover{border-color: 11;}
background-color: 2;
background-clip: padding-box;
&:hover {background-color: 11;}
width:3px;
height:3px;
@media #{$medium-up} {
width:3px;
height:3px;
};
@media #{$large-up} {
width:3px;
height:3px;
};
&:hover{background-color: 11;}
}}
 }
/* Responsive Menu:20 */
nav.responsive-menu {
.menu-item.MEC20{background-color: var(--clr-65813);}
& .menu-item.MEC20, & .menu-item.MEC20 > div.menu-item-wrap{ & > a.MEC20, & > i{color: var(--clr-65810);
text-transform: uppercase;
text-align: center;
}
  }
& .menu-item.MEC20 { border:0;

 border-bottom-width: 1px; }
& .menu-item > .menu-item-wrap {padding: 0;}

& .sub-menu{ .sub-menu {}}

 }
/* login buttons:21 */
.MES21 {
background-color: var(--clr-65811);
color: #ffffff;
border-radius: 50px;
padding: 5px 15px;

border-width: 1px;
border-style: solid;
border-color: transparent;
 }
/* Copyright panel grey:22 */
.MES22 {
background-color: var(--clr-65813);
font-size: 12.8px;
@media #{$large-up} {
font-size: 14.4px;
};
 }
.MES22 {
background-color: var(--clr-65813);
font-size: 12.8px;
@media #{$large-up} {
font-size: 14.4px;
};
 }
cite.MEC22{
font-size: 12.8px;
@media #{$large-up} {
font-size: 14.4px;
};
}
/* carousel padding:23 */
.MES23 {
background-color: transparent;
&:hover, &.hover { background-color: var(--clr-65815);}
padding: 10px 20px;

border-width: 1px;
border-style: solid;
border-color: var(--clr-65815);
 }
.MES23 {
background-color: transparent;
&:hover, &.hover { background-color: var(--clr-65815);}
padding: 10px 20px;

border-width: 1px;
border-style: solid;
border-color: var(--clr-65815);
 }
/* Carousel grey pad:24 */
.MES24 {
background-color: var(--clr-65815);
padding: 20px 15px;

@media #{$large-up} {
padding: 20px;

}
 }
.MES24 {
background-color: var(--clr-65815);
padding: 20px 15px;

@media #{$large-up} {
padding: 20px;

}
 }
/* border line:25 */
.MES25 {
& > hr {border-width: 1px 0 0 0;}
& > hr {border-color: var(--clr-65815) transparent transparent transparent;}
& > hr {border-top-style: solid;}
 }
/* Top Strip Panel:26 */
.MES26 {
background-color: #ffffff;
color: var(--clr-65810);
font-size: 16px;
@media #{$large-up} {
font-size: 14.4px;
};
padding: 5px;

border-width: 0 0 1px 0;
border-style: solid;
border-color: transparent transparent var(--clr-65813) transparent;
border-top-style: solid;
border-right-style: solid;
border-bottom-style: solid;
border-left-style: solid;
 }
.MES26 {
background-color: #ffffff;
color: var(--clr-65810);
font-size: 16px;
@media #{$large-up} {
font-size: 14.4px;
};
padding: 5px;

border-width: 0 0 1px 0;
border-style: solid;
border-color: transparent transparent var(--clr-65813) transparent;
border-top-style: solid;
border-right-style: solid;
border-bottom-style: solid;
border-left-style: solid;
h1.MEC26, h2.MEC26, h3.MEC26, h4.MEC26, h5.MEC26, h6.MEC26 { color: var(--clr-65814);
 }
h1.MEC26 { @media #{$medium-up} { font-size: 25.6px; }; }
h1.MEC26 { @media #{$large-up} { font-size: 36px; }; }
h2.MEC26 { @media #{$medium-up} { font-size: 24px; }; }
h2.MEC26 { @media #{$large-up} { font-size: 33px; }; }
h3.MEC26 { @media #{$medium-up} { font-size: 20.8px; }; }
h3.MEC26 { @media #{$large-up} { font-size: 30px; }; }
h4.MEC26 { @media #{$medium-up} { font-size: 16px; }; }
h4.MEC26 { @media #{$large-up} { font-size: 25px; }; }
h5.MEC26 { @media #{$medium-up} { font-size: 12px; }; }
h5.MEC26 { @media #{$large-up} { font-size: 16px; }; }
h6.MEC26 { @media #{$medium-up} { font-size: 12.8px; }; }
h6.MEC26 { @media #{$large-up} { font-size: 16px; }; }
 }
a.MEC26 { color: var(--clr-65810);
&:hover { color: var(--clr-65814);}
 }
cite.MEC26{
color: var(--clr-65810);
font-size: 16px;
@media #{$large-up} {
font-size: 14.4px;
};
}
/* Transparent White Panel:27 */
.MES27 {
background-color: var(--clr-65817);
padding: 20px;

 }
.MES27 {
background-color: var(--clr-65817);
padding: 20px;

 }
/* Information Block Padding Grey outline:28 */
.MES28 {
padding: 20px 10px;

@media #{$large-up} {
padding: 20px;

}
border-width: 1px;
border-style: solid;
border-color: var(--clr-65815);
 }
.MES28 {
padding: 20px 10px;

@media #{$large-up} {
padding: 20px;

}
border-width: 1px;
border-style: solid;
border-color: var(--clr-65815);
 }
/* Header white banner:29 */
.MES29 {
background-color: #ffffff;
 }
.MES29 {
background-color: #ffffff;
 }
/* Transparent black Panel:30 */
.MES30 {
background-color: var(--clr-65818);
color: #ffffff;
padding: 20px;

 }
.MES30 {
background-color: var(--clr-65818);
color: #ffffff;
padding: 20px;

h1.MEC30, h2.MEC30, h3.MEC30, h4.MEC30, h5.MEC30, h6.MEC30 { color: #ffffff;
 }
 }
cite.MEC30{
color: #ffffff;
}
/* Black Link:31 */
.MES31 {
color: transparent;
 }
.MES31 {
color: transparent;
 }
a.MEC31 { color: var(--clr-65810);
&:hover { color: var(--clr-65812);}
 }
cite.MEC31{
color: transparent;
}
/* button outline:32 */
.MES32 {
background-color: transparent;
&:hover {background-color: var(--clr-65813);}
color: var(--clr-65810);
&:hover { color: var(--clr-65810);}
border-radius: 10px;
padding: 5px 15px;

border-width: 1px;
border-style: solid;
border-color: var(--clr-65810);
 }
/* Button plain text:33 */
.MES33 {
color: var(--clr-65810);
&:hover { color: var(--clr-65812);}
@media #{$large-up} {
padding: 0;

}
 }
/* Main Menu Panel:34 */
.MES34 {
background-color: var(--clr-65815);
 }
.MES34 {
background-color: var(--clr-65815);
 }
a.MEC34 { color: var(--clr-65810);
&:hover { color: var(--clr-65811);}
 }
/* Shade 2:35 */
.MES35 {
background-color: #5d614c;
color: #ffffff;
&:hover { color: #ffffff;}
 }
/* Shade 2:36 */
.MES36 {
background-color: #5d614c;
color: #ffffff;
 }
.MES36 {
background-color: #5d614c;
color: #ffffff;
h1.MEC36, h2.MEC36, h3.MEC36, h4.MEC36, h5.MEC36, h6.MEC36 { color: #ffffff;
 }
 }
cite.MEC36{
color: #ffffff;
}
/* Shade 3:37 */
.MES37 {
background-color: #59422c;
color: #ffffff;
&:hover { color: #ffffff;}
 }
/* Shade 3:38 */
.MES38 {
background-color: #59422c;
color: #ffffff;
padding: 5px;

 }
.MES38 {
background-color: #59422c;
color: #ffffff;
padding: 5px;

h1.MEC38, h2.MEC38, h3.MEC38, h4.MEC38, h5.MEC38, h6.MEC38 { color: #ffffff;
 }
 }
cite.MEC38{
color: #ffffff;
}
/* Information Block Grey outline:39 */
.MES39 {
border-width: 1px;
border-style: solid;
border-color: var(--clr-65815);
 }
.MES39 {
border-width: 1px;
border-style: solid;
border-color: var(--clr-65815);
 }
/* White content block:40 */
.MES40 {
background-color: #ffffff;
padding: 20px 0;

@media #{$large-up} {
padding: 50px 0;

}
 }
.MES40 {
background-color: #ffffff;
padding: 20px 0;

@media #{$large-up} {
padding: 50px 0;

}
 }
/* Dark:42 */
.MES42 {
background-color: #ffffff;
 }
/* Dark:43 */
.MES43 {
background-color: #ffffff;
 }
.MES43 {
background-color: #ffffff;
 }
/* Menu Profile:44 */
nav.me-Menu.MES44 {
& .menu-item.MEC44, & .menu-item.MEC44 > div.MEC44{ & > a.MEC44{color: var(--clr-65810);
font-size: 14.4px;
text-transform: uppercase;
}
 &:hover > a.MEC44{color: var(--clr-65812);
}
 }
&.horizontal > .menu-item.MEC44 { border:0;

 border-right-width: 1px;}
&.vertical > .menu-item.MEC44 { border:0;

border-bottom-width: 1px; }
&.horizontal > .menu-item.MEC44 .menu-item { border:0;
border-bottom-width: 1px;
 }
&.horizontal > .menu-item.MEC44 .sub-menu { border:1px;
 }
& > .menu-item > a{padding: 5px 15px;}

& .sub-menu .menu-item a{padding: 5px 20px;}



&.horizontal .menu-item.MEC44:hover {& > .pointer-wrap > .pointer{border-bottom: 10px solid #ffffff;}}
&.vertical .menu-item.MEC44:hover {& > .pointer-wrap > .pointer{border-right: 10px solid #ffffff;
}}

.menu-item:hover {
& > .sub-menu, & > .pointer-wrap > .sub-menu {
.menu-item.MEC44{background-color: #ffffff; &:hover {background-color: 4;}
}
& .menu-item.MEC44, & .menu-item.MEC44 > div.MEC44{ & > a.MEC44{color: var(--clr-65810);
}
 &:hover > a.MEC44{color: 2;
}
 }

}
}
 }
/* Exhbition Images:45 */
.MES45 {
& .slider-arrow {color: var(--clr-65818);
font-size: 80px;
@media #{$medium-up} {
font-size: 80px;
};
@media #{$large-up} {
font-size: 80px;
};
}& .slick-dots {text-align: center;
 button {margin: 0 8px;
;border-color: 1;
border-radius: 50%;
border-style:solid;
border-width:2px;
@media #{$medium-up} {
border-width: 2px;
};
@media #{$large-up} {
border-width: 2px;};
&:hover{border-color: 1;}
background-color: 19;
background-clip: padding-box;
&:hover {background-color: 4;}
width:10px;
height:10px;
@media #{$medium-up} {
width:10px;
height:10px;
};
@media #{$large-up} {
width:10px;
height:10px;
};
&:hover{background-color: 4;}
}}
 }
/* Line:46 */
.MES46 {
padding: 0 0 5px 0;

border-width: 0 0 1px 0;
border-style: solid;
border-color: var(--clr-65815);
 }
.MES46 {
padding: 0 0 5px 0;

border-width: 0 0 1px 0;
border-style: solid;
border-color: var(--clr-65815);
 }
/* border line:47 */
.MES47 {
& > hr {border-width: 1px 0 0 0;}
& > hr {border-color: var(--clr-65815) transparent transparent transparent;}
& > hr {border-top-style: solid;}
 }
/* Button Test:48 */
.MES48 {
background-color: #ffffff;
color: var(--clr-65810);
font-size: 14px;
 }
/* Invisible:49 */
.MES49 {
padding: 15px;

 }
/* Transparent black roll over:50 */
.MES50 {
background-color: var(--clr-65818);
&:hover, &.hover { background-color: var(--clr-65821);}
color: #ffffff;
padding: 20px 10px;

@media #{$medium-up} {
padding: 20px;

}
 }
.MES50 {
background-color: var(--clr-65818);
&:hover, &.hover { background-color: var(--clr-65821);}
color: #ffffff;
padding: 20px 10px;

@media #{$medium-up} {
padding: 20px;

}
h1.MEC50, h2.MEC50, h3.MEC50, h4.MEC50, h5.MEC50, h6.MEC50 { color: #ffffff;
 }
&:hover { h1.MEC50, h2.MEC50, h3.MEC50, h4.MEC50, h5.MEC50, h6.MEC50 { color: #ffffff;} }
 }
cite.MEC50{
color: #ffffff;
}
/* Blue button:51 */
.MES51 {
background-color: var(--clr-65819);
&:hover {background-color: var(--clr-65814);}
color: #ffffff;
&:hover { color: #ffffff;}
border-radius: 5px;
padding: 3px 5px;

@media #{$medium-up} {
padding: 3px 10px;

}
@media #{$large-up} {
padding: 3px 15px;

}
border-width: 1px;
border-style: solid;
border-color: #ffffff;
 }
/* Secondary Menu:52 */
nav.me-Menu.MES52 {
& .menu-item.MEC52, & .menu-item.MEC52 > div.MEC52{ & > a.MEC52{color: var(--clr-65810);
text-transform: uppercase;
}
 &:hover > a.MEC52{color: var(--clr-65812);
}
 }
&.horizontal > .menu-item.MEC52 { border:0;

 border-right-width: 1px;}
&.vertical > .menu-item.MEC52 { border:0;

border-bottom-width: 1px; }
&.horizontal > .menu-item.MEC52 .menu-item { border:0;
border-bottom-width: 1px;
 }
&.horizontal > .menu-item.MEC52 .sub-menu { border:1px;
 }
& > .menu-item > a{padding: 0 8px;}

@media #{$medium-up} {
& > .menu-item > a{padding: 0 15px;}

}
& .sub-menu .menu-item a{padding: 0 20px;}

@media #{$large-up} {
& .sub-menu .menu-item a{padding: 0 30px;}

}


&.horizontal .menu-item.MEC52:hover {& > .pointer-wrap > .pointer{border-bottom: 10px solid #ffffff;}}
&.vertical .menu-item.MEC52:hover {& > .pointer-wrap > .pointer{border-right: 10px solid #ffffff;
}}

.menu-item:hover {
& > .sub-menu, & > .pointer-wrap > .sub-menu {
.menu-item.MEC52{background-color: #ffffff; &:hover {background-color: 4;}
}
& .menu-item.MEC52, & .menu-item.MEC52 > div.MEC52{ & > a.MEC52{color: var(--clr-65810);
}
 &:hover > a.MEC52{color: 2;
}
 }

}
}
 }
/* Drop area style:53 */
.MES53 {
padding: 15px;

border-width: 4px;
border-style: solid;
border-color: var(--clr-65812);
border-top-style: dashed;
border-right-style: dashed;
border-bottom-style: dashed;
border-left-style: dashed;
 }
.MES53 {
padding: 15px;

border-width: 4px;
border-style: solid;
border-color: var(--clr-65812);
border-top-style: dashed;
border-right-style: dashed;
border-bottom-style: dashed;
border-left-style: dashed;
 }
/* With Border:54 */
.MES54 {
border-width: 2px;
border-style: solid;
border-color: var(--clr-65820);
 }
.MES54 {
border-width: 2px;
border-style: solid;
border-color: var(--clr-65820);
 }
/* Green button Rounded:55 */
.MES55 {
background-color: var(--clr-65814);
&:hover {background-color: var(--clr-65814);}
color: #ffffff;
&:hover { color: #ffffffb3;}
border-radius: 5px;
padding: 3px 5px;

@media #{$medium-up} {
padding: 3px 10px;

}
@media #{$large-up} {
padding: 3px 15px;

}
border-width: 1px;
border-style: solid;
border-color: #ffffff;
 }
/* Red:56 */
.MES56 {
 }
/* :57 */
.MES57 {
background-color: var(--clr-65811);
border-width: 3px;
border-style: solid;
border-color: var(--clr-65814);
 }
/* Xmas:58 */
.MES58 {
border-top-style: ridge;
border-right-style: ridge;
border-bottom-style: ridge;
border-left-style: ridge;
 }
.MES58 {
border-top-style: ridge;
border-right-style: ridge;
border-bottom-style: ridge;
border-left-style: ridge;
 }
/* Xmas green:59 */
.MES59 {
 }
.MES59 {
 }
/* Secondary:61 */
.MES61 {
background-color: var(--clr-65814);
color: #ffffff;
&:hover { color: #ffffff;}
 }
/* Large Text Button:62 */
.MES62 {
background-color: var(--clr-65814);
color: #ffffff;
&:hover { color: #ffffff;}
font-size: 21px;
 }
/* :63 */
.MES63 {
background-color: var(--clr-65814);
 }
/* Information Block Padding Green outline:64 */
.MES64 {
background-color: var(--clr-65822);
padding: 20px 10px;

@media #{$large-up} {
padding: 20px;

}
border-width: 1px;
border-style: solid;
border-color: var(--clr-65814);
 }
.MES64 {
background-color: var(--clr-65822);
padding: 20px 10px;

@media #{$large-up} {
padding: 20px;

}
border-width: 1px;
border-style: solid;
border-color: var(--clr-65814);
 }
/* White Text Button:65 */
.MES65 {
color: var(--clr-65813);
&:hover { color: var(--clr-65813);}
font-size: 21px;
 }
/* Green button Rounded Large:66 */
.MES66 {
background-color: var(--clr-65814);
&:hover {background-color: var(--clr-65814);}
color: #ffffff;
&:hover { color: var(--clr-65815);}
font-size: 14px;
@media #{$medium-up} {
font-size: 17.64px;
};
@media #{$large-up} {
font-size: 20.86px;
};
border-radius: 5px;
padding: 3px 5px;

@media #{$medium-up} {
padding: 3px 10px;

}
@media #{$large-up} {
padding: 3px 15px;

}
border-width: 1px;
border-style: solid;
border-color: #ffffff;
 }
/* Orange button Rounded:67 */
.MES67 {
background-color: var(--clr-65823);
&:hover {background-color: var(--clr-65823);}
color: #ffffff;
&:hover { color: var(--clr-65815);}
border-radius: 5px;
padding: 3px 5px;

@media #{$medium-up} {
padding: 3px 10px;

}
@media #{$large-up} {
padding: 3px 15px;

}
border-width: 1px;
border-style: solid;
border-color: #ffffff;
 }
/* Red button Rounded:68 */
.MES68 {
background-color: var(--clr-65811);
&:hover {background-color: var(--clr-65811);}
color: #ffffff;
&:hover { color: var(--clr-65815);}
border-radius: 5px;
padding: 3px 5px;

@media #{$medium-up} {
padding: 3px 10px;

}
@media #{$large-up} {
padding: 3px 15px;

}
border-width: 1px;
border-style: solid;
border-color: #ffffff;
 }
/* Page Accordion:69 */
details.MES69 {
 }
